import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

const Hero = () => {
  const { hero } = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "corporate-solutions-banner" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 400, maxHeight: 170, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledBackgroundImage Tag="section" fluid={hero.sharp.fluid} style={{height: "100%", width: "100%"}}>
      <div className="grid grid-cols-8 md:grid-cols-12 z-20 p-1 pt-4 lg:pt-20 text-white lg:mb-0 h-[60vh]">
        <div className="align-text-bottom row-start-4 text-text-bottom md:col-start-2 col-span-8 lg:block p-6">
          <h1 className="mt-auto text-left font-light tracking-tight text-4xl sm:text-5xl lg:text-6xl">
            <span className="block text-primary">
              <span className="block sm:inline text-white font-bold">Corporate Solutions</span>
            </span>
            <span className="text-xl mt-4 font-normal text-highlight-dark">
              <span className="block sm:inline text-white">Digital App &nbsp;|&nbsp; Gift Cards &nbsp;|&nbsp; My Receipt Rewards</span>
            </span>
          </h1>
        </div>
      </div>
    </StyledBackgroundImage>
  )
}

// Darken background image so doesn't clash with white text/content
const StyledBackgroundImage = styled(BackgroundImage)`
   &::before,
   &::after {
    filter: brightness(0.7);
  }
`;

export default Hero;
